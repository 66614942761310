import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'

const NotFoundPage = ({ data }) => {
  return (
    <Layout>
      <Seo
        title="Not Found 404"
        description="You've 'RUN' into a problem, cannot find the page you looking for"
      />

      <div className="bg-light pt-8 pb-5 pr-5 pl-5 md:pr-10 md:pl-10 mt-4 mb-4 max-w-full rounded-md prose">
        <h1>You've 'RUN' into a problem, cannot find the page you looking for.</h1>
        <p className="text-lg pb-2">
          Try looking for the page on google using this search term site: runningdeveloper.com search term
        </p>

      </div>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
        title
      }
    }
  }
`
